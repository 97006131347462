<template>
  <div>
    <div class="card">
      <!-- <div class="card-header">
        <h5 class="card-title">User Account</h5>
      </div> -->
      <div class="card-body row">

        <div class="col-sm-12">
          <div class="pull-left"><h5 class="card-title">Order Summary</h5></div>
          <div class="pull-right" v-if="listMode">
            <el-input prefix-icon="el-icon-search" v-model="textSearch" size="small" placeholder="Shop Name" />
          </div>
        </div>

        <div class="col-sm-12" v-if="listMode">
          <el-table :data="tableData" header-row-class-name="text-primary">
            <el-table-column type="index" width="80"></el-table-column>
            <el-table-column prop="shopname" label="Shop Name"></el-table-column>
            <el-table-column prop="totalOrder" label="Order"></el-table-column>
            <el-table-column class-name="action-buttons td-actions" width="140" align="right" label="Actions">
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="showShopOrder(props.$index, props.row)">
                  <i class="nc-icon nc-paper"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
          <br />
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.currentPage"
              :page-sizes="pagination.pageSizes"
              :page-size="pagination.pageSize"
              :total="pagination.total"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>

        <div class="col-sm-12" v-else>
          <div class="row">
            <div class="col-md-6"><h6 class="card-title">Shop Name : {{shopName}}</h6></div>
          </div>

          <el-table :data="orders" header-row-class-name="text-primary">
            <!-- <el-table-column type="index" width="80"></el-table-column> -->
            <el-table-column prop="id" label="ID"></el-table-column>
            <el-table-column prop="entryDate" label="Date"></el-table-column>
            <el-table-column prop="lotname" label="Lot Name"></el-table-column>
            <el-table-column prop="totalPrice" label="Total"></el-table-column>
            <el-table-column prop="paid" label="Paid"></el-table-column>
            <el-table-column prop="status" label="Shipment"></el-table-column>
            <el-table-column class-name="action-buttons td-actions" width="140" align="right" label="Actions">
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="showOrderDetail(props.$index, props.row)">
                  <i class="nc-icon nc-paper"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="row">
            <div class="col-md-5"></div>
            <div class="col-md-6">
              <p-button type="danger" round @click="listMode=true"> Close </p-button>
            </div>
          </div>
        </div>

      </div>
    </div>
    
  </div>
</template>

<script>
  import Vue from 'vue'
  import {Table, TableColumn} from 'element-ui'
  import {DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Pagination} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import NotificationTemplate from 'src/components/Dashboard/Views/Components/NotificationTemplate'
  import axios from 'axios'

  Vue.use(Table)
  Vue.use(TableColumn)

  export default{
    components: {
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      [Slider.name]: Slider,
      [Tag.name]: Tag,
      [Input.name]: Input,
      [Button.name]: Button,
      [Option.name]: Option,
      [Select.name]: Select,
      [Pagination.name]: Pagination,
      PSwitch,
      NotificationTemplate
    },
    data () {
      return {
        userID: 0,
        listMode: true,
        tableData: [],
        switches: {
          defaultOn: true,
          defaultOff: false,
          plainOn: true,
          plainOff: false,
          withIconsOn: true,
          withIconsOff: false
        },
        shopName: '',
        action: 'add',
        orders: [],
        textSearch: '',
        pagination: {
          pageSizes: [10, 20, 50],
          pageSize: 10,
          total: 0,
          currentPage: 1,
        },
        apiUrl: process.env.NODE_ENV === 'production'?'https://www.hbrxws.com/api':'http://localhost/hbrxws/api',
      }
    },
    mounted() {
      this.loadItem();
    },
    methods: {
      async loadItem () {
        const token = localStorage.getItem('authToken');

        axios.post(
            this.apiUrl+'/user?action=getUserID', { token:token },
            { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
            this.userID = response.data.id;

            axios.post(
                this.apiUrl+'/userAccount?action=orderSummary',
                { 
                    'pageSize': this.pagination.pageSize,
                    'currentPage': this.pagination.currentPage,
                    'q': this.textSearch,
                    'userID': this.userID,
                },
                { headers: { 'Content-Type': 'multipart/form-data' } }
            )
            .then(response => {
                this.tableData = response.data.rows;
                this.pagination.total = parseInt(response.data.total);
            })
            .catch(error => {
                this.error = error;
            });
        });

      },
      async showShopOrder (index, row) {
        this.listMode=false;
        this.action='new'

        this.shopName = row.shopname

        this.loadOrders(row)
      },
      async loadOrders (params) {
        axios.post(
          this.apiUrl+'/orders',
          { 
            'pageSize': this.pagination.pageSize,
            'currentPage': this.pagination.currentPage,
            'q': this.textSearch,
            // 'userID': params.id,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.orders = response.data.rows;
          this.pagination.total = parseInt(response.data.total);
        })
        .catch(error => {
          this.error = error;
        });

      },
      async showOrderDetail (index, params) {
        this.$router.push('/user/order-detail/'+params.id);
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val;
        this.loadItem();
      },
      handleCurrentChange(val) {
        this.pagination.currentPage = val;
        this.loadItem();
      },
    }
  }
</script>

<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
</style>
