<template>
  <div>
    <div class="card">
      <!-- <div class="card-header"><h5 class="card-title">Products</h5></div> -->
      <div class="card-body row">

        <div class="col-sm-12">
          <div class="pull-left"><h5 class="card-title">Brand</h5></div>
          <div class="pull-right" v-if="listMode">
            <el-input prefix-icon="el-icon-search" v-model="textSearch" size="small" placeholder="Search by name"
              @input="loadItems"
            />
          </div>
        </div>

        <div class="col-sm-12" v-if="listMode">
          <el-table :data="items" header-row-class-name="text-primary">
            <!-- <el-table-column type="index"></el-table-column> -->
            <el-table-column prop="id" label="ID" width="60"></el-table-column>
            <el-table-column prop="name" label="Name"></el-table-column>
            <el-table-column prop="status" label="Active"></el-table-column>
            <el-table-column class-name="action-buttons td-actions" width="140" align="right" label="Actions">
              <template slot="header" slot-scope="scope">
                <p-button type="info" round @click="newItem()">add new</p-button>
              </template>
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="editItem(props.$index, props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="confirmToDelete(props.$index, props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
          <br />
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.currentPage"
              :page-sizes="pagination.pageSizes"
              :page-size="pagination.pageSize"
              :total="pagination.total"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>

        <div class="col-sm-12" v-else>
          <div class="extended-forms">
            <div class="card">
              <div class="card-body">

                <div class="row">
                  <div class="col-md-4">
                    <h5 class="card-title">Name</h5>
                    <fg-input placeholder="" v-model="item.name"></fg-input>
                  </div>
                  <div class="col-md-4">
                    <h5 class="card-title">Active</h5>
                    <div class="row">
                      <div class="col-sm-12">
                        <fg-input>
                          <el-select class="select-success" size="large" placeholder="" v-model="item.status">
                            <el-option class="select-success" value="YES" label="YES"></el-option>
                            <el-option class="select-success" value="NO" label="NO"></el-option>
                          </el-select>
                        </fg-input>
                      </div>                     
                    </div>
                  </div>

                </div>
                <br/>

                <!-- <div class="row">
                  <div class="col-md-4">
                    <h5 class="card-title">เปิดใช้งาน</h5>
                    <div class="col-md-6">
                      <p-switch v-model="item.active" type="primary" on-text="ON" off-text="OFF"></p-switch>
                    </div>
                  </div>
                </div>
                <br /> -->

                <div class="row">
                  <div class="col-md-5"></div>
                  <div class="col-md-6">
                    <p-button type="info" round @click="addItem()" v-if="action=='new'"> Add </p-button>
                    <p-button type="info" round @click="updateItem()" v-if="action=='edit'"> Update </p-button>
                    &nbsp;
                    <p-button type="danger" round @click="listMode=true"> cancel </p-button>
                  </div>
                </div>

                <br/><br/>
              </div>
            </div> <!-- end card -->
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue'
  import {Table, TableColumn} from 'element-ui'
  import {DatePicker, TimeSelect, Input, Button, Select, Option, Upload, Dialog, Pagination, MessageBox} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import NotificationTemplate from 'src/components/Dashboard/Views/Components/NotificationTemplate'
  import axios from 'axios'

  Vue.use(Table)
  Vue.use(TableColumn)
  export default{
    components: {
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      [Input.name]: Input,
      [Button.name]: Button,
      [Option.name]: Option,
      [Select.name]: Select,
      [Upload.name]: Upload,
      [Dialog.name]: Dialog,
      [Pagination.name]: Pagination,
      PSwitch,
    },
    data () {
      return {
        disabled: false,
        listMode: true,
        items: [],
        action: 'add',
        item :{
          name : '',
          status: '',
        },
        textSearch: '',
        pagination: {
          pageSizes: [10, 20, 50],
          pageSize: 10,
          total: 0,
          currentPage: 1,
        },
        apiUrl: process.env.NODE_ENV === 'production'?'https://www.hbrxws.com/api':'http://localhost/hbrxws/api',
      }
    },
    mounted() {
      this.loadItems()
    },
    methods: {
      confirmToDelete (index, row) {
        MessageBox.confirm('This will permanently delete. Continue?', 'Warning', {
          confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning'
        }).then(() => {
          this.deleteItem (index, row);
        }).catch(() => {
          
        });
      },
      async loadItems () {
        axios.post(
          this.apiUrl+'/products?action=listBrand',
          { 
            'pageSize': this.pagination.pageSize,
            'currentPage': this.pagination.currentPage,
            'q': this.textSearch,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.items = response.data.rows;
          this.pagination.total = parseInt(response.data.total);
        })
        .catch(error => {
          this.error = error;
        });

      },
      async newItem () {
        this.listMode=false;
        this.action='new';

        this.item.name = '';
        this.item.status = '';

      },
      async addItem () {
        const formData = new FormData();
        
        formData.append('name', this.item.name);
        formData.append('status', this.item.status);
        
        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/products?action=newBrand', formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          );
         
          this.listMode = true;
          this.loadItems();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'เพิ่มรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: error
          })
        }
      },
      async editItem (index, row) {
        this.listMode = false
        this.action = 'edit'

        this.item = row;

        this.fileList = [{
          name: row.imageFile,
          url: row.imageUrl,
        }];
        
      },
      async updateItem () {
        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/products?action=updateBrand', this.item,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          );

          this.listMode = true;
          this.loadItems();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'บันทึกรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: error+'<br />'
          })
        }
      },
      async deleteItem (index, row) {
        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/products?action=deleteBrand', row,
            {
              headers: { 'Content-Type': 'multipart/form-data' }
            }
          );

          this.loadItems();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'ลบรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: error
          })
        }
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val;
        this.loadOrders();
      },
      handleCurrentChange(val) {
        this.pagination.currentPage = val;
        this.loadOrders();
      },
    }
  }
</script>

<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
</style>
