<template>
  <div>
    <div class="card">
      <!-- <div class="card-header"><h5 class="card-title">Products</h5></div> -->
      <div class="card-body row">

        <div class="col-sm-12">
          <div class="pull-left"><h5 class="card-title">Products</h5></div>
          <div class="pull-right" v-if="listMode">
            <div class="col-sm-12">
              <el-input prefix-icon="el-icon-search" v-model="textSearch" size="small" placeholder="Name & Brand"
                @input="loadItems"
              />
            </div>
          </div>
        </div>

        <div class="col-sm-12" v-if="listMode">
          <el-table header-row-class-name="text-primary"
            ref="filterTable"
            :data="items"
            @sort-change="sortChange"
          >
            <!-- <el-table-column type="index"></el-table-column> -->
            <el-table-column prop="id" label="ID" width="60" sortable="custom"></el-table-column>
            <el-table-column width="90">
              <template slot-scope="props">
                <div class="img-container"><img :src="props.row.imageUrl" alt=""></div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="Name" sortable="custom" min-width="130"></el-table-column>
            <el-table-column prop="brand" label="Brand" sortable="custom"></el-table-column>
            <el-table-column prop="srp" label="SRP" sortable="custom" ></el-table-column>
            <el-table-column prop="wsp" label="WSP" sortable="custom"></el-table-column>
            <el-table-column prop="deposit" label="Deposit" sortable="custom"></el-table-column>
            <el-table-column prop="status" label="Status" sortable="custom" width="100"></el-table-column>
            <el-table-column class-name="action-buttons td-actions" width="135" align="center" label="Actions">
              <template slot="header" slot-scope="scope">
                <p-button type="info" round @click="newItem()">add new</p-button>
              </template>
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="editItem(props.$index, props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="confirmToDelete(props.$index, props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
          <br />
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.currentPage"
              :page-sizes="pagination.pageSizes"
              :page-size="pagination.pageSize"
              :total="pagination.total"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>

        <div class="col-sm-12" v-else>
          <div class="extended-forms">
            <div class="card">
              <div class="card-body">

                <div class="row">
                  <div class="col-md-12">
                    <el-upload list-type="picture-card" ref="upload"
                      :action="apiUrl+'/products?action=upload'"
                      :limit = "1"                     
                      :before-upload = "beforeUpload"
                      :on-success="handleSuccess"
                      :file-list="fileList"
                    >
                        <i slot="default" class="el-icon-plus"></i>
                        <div slot="file" slot-scope="{file}">
                          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" >
                          <span class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)" >
                              <i class="el-icon-zoom-in"></i>
                            </span>
                            <!-- 
                            <span v-if="!disabled" class="el-upload-list__item-delete" @click="handlePictureDownload(file)">
                              <i class="el-icon-download"></i>
                            </span>
                            -->
                            <span v-if="!disabled" class="el-upload-list__item-delete" @click="handlePictureRemove(file)">
                              <i class="el-icon-delete"></i>
                            </span>
                          </span>
                        </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <h5 class="card-title">Item Name</h5>
                    <fg-input placeholder="" v-model="item.name"></fg-input>
                  </div>
                  <div class="col-md-4">
                    <h5 class="card-title">Brand</h5>
                    <!-- <fg-input placeholder="" v-model="item.brand"></fg-input> -->
                    <fg-input>
                      <el-select class="select-success" size="large" placeholder="" v-model="item.brand">
                        <el-option v-for="option in selectsBrand.options" class="select-success"
                          :value="option.name" :label="option.name" :key="option.id">
                        </el-option>
                      </el-select>
                    </fg-input>
                  </div>
                  <div class="col-md-4">
                    <h5 class="card-title">Category</h5>
                    <!-- <fg-input placeholder="" v-model="item.category"></fg-input> -->
                    <fg-input>
                      <el-select class="select-success" size="large" placeholder="" v-model="item.category">
                        <el-option v-for="option in selectsCategory.options" class="select-success"
                          :value="option.name" :label="option.name" :key="option.id">
                        </el-option>
                      </el-select>
                    </fg-input>
                  </div>
                </div>
                <br/>

                <div class="row">
                  <div class="col-md-4">
                    <h5 class="card-title">SRP (ราคาขาย)</h5>
                    <fg-input placeholder="0.00" type="number" v-model="item.srp"></fg-input>
                  </div>
                  <div class="col-md-4">
                    <h5 class="card-title">WSP (ราคาส่ง)</h5>
                    <fg-input placeholder="0.00" type="number" v-model="item.wsp"></fg-input>
                  </div>
                  <div class="col-md-4">
                    <h5 class="card-title">Deposit (มัดจำ)</h5>
                    <fg-input placeholder="0.00" type="number" v-model="item.deposit"></fg-input>
                  </div>
                </div>
                <br/>

                <div class="row">
                  <div class="col-md-4">
                    <h5 class="card-title">Order Start</h5>
                    <fg-input>
                      <el-date-picker v-model="item.startDate" type="datetime"
                        placeholder="Select date and time">
                      </el-date-picker>
                    </fg-input>
                  </div>
                  <div class="col-md-4">
                    <h5 class="card-title">Order Ends</h5>
                    <fg-input>
                      <el-date-picker v-model="item.endDate" type="datetime"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="Select date and time">
                      </el-date-picker>
                    </fg-input>
                  </div>
                  <div class="col-md-4">
                    <h5 class="card-title">Appox. Delivery</h5>
                    <fg-input>
                      <el-date-picker v-model="item.deliveryDate" type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        placeholder="Select date and time">
                      </el-date-picker>
                    </fg-input>
                  </div>
                </div>
                <br/>
                
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="card-title">หมายเหตุ</h5>
                    <fg-input placeholder="" v-model="item.remark"></fg-input>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col-md-4">
                    <h5 class="card-title">Status</h5>
                    
                      <fg-input>
                          <el-select class="select-success" size="large" placeholder="" v-model="item.status">
                            <el-option class="select-success" value="Active" label="Active"></el-option>
                            <el-option class="select-success" value="Inactive" label="Inactive"></el-option>
                          </el-select>
                        </fg-input>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col-md-5"></div>
                  <div class="col-md-6">
                    <p-button type="info" round @click="addItem()" v-if="action=='new'"> Add </p-button>
                    <p-button type="info" round @click="updateItem()" v-if="action=='edit'"> Update </p-button>
                    &nbsp;
                    <p-button type="danger" round @click="listMode=true"> cancel </p-button>
                  </div>
                </div>

                <br/><br/>
              </div>
            </div> <!-- end card -->
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue'
  import {Table, TableColumn} from 'element-ui'
  import {DatePicker, TimeSelect, Input, Button, Select, Option, Upload, Dialog, Pagination, MessageBox} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import NotificationTemplate from 'src/components/Dashboard/Views/Components/NotificationTemplate'
  import axios from 'axios'
  import moment from 'moment'

  Vue.use(Table)
  Vue.use(TableColumn)
  export default{
    components: {
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      [Input.name]: Input,
      [Button.name]: Button,
      [Option.name]: Option,
      [Select.name]: Select,
      [Upload.name]: Upload,
      [Dialog.name]: Dialog,
      [Pagination.name]: Pagination,
      PSwitch,
    },
    data () {
      return {
        selectsBrand: {
          simple: '',
          options:[
            { value:'KASITHBK', label:'bbbb' },
          ],
          multiple: 'ARS'
        },
        selectsCategory: {
          simple: '',
          options:[
            { value:'KASITHBK', label:'cccc' },
          ],
          multiple: 'ARS'
        },
        datePicker: '',
        dateTimePicker: '',
        timePicker: '',
        dialogImageUrl: '',
        dialogVisible: false,
        fileList: [],
        disabled: false,
        listMode: true,
        items: [],
        action: 'add',
        item :{
          imageFile : null,
          imageUrl : '',
          name : '',
          brand: '',
          category: '',
          srp: 0.00,
          wsp: 0.00,
          deposit: 0.00,
          startDate: '',
          endDate: '',
          deliveryDate: '',
          remark: '',
        },
        textSearch: '',
        pagination: {
          pageSizes: [10, 20, 50],
          pageSize: 10,
          total: 0,
          currentPage: 1,
          sortBy: 'id',
          orderBy: 'desc',
        },
        apiUrl: process.env.NODE_ENV === 'production'?'https://www.hbrxws.com/api':'http://localhost/hbrxws/api',
      }
    },
    mounted() {
      this.loadBrand()
      this.loadCategory()
      this.loadItems()
    },
    methods: {
      formatDate(value) {
        // Implement custom formatting logic here
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      },
      confirmToDelete (index, row) {
        MessageBox.confirm('This will permanently delete. Continue?', 'Warning', {
          confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning'
        }).then(() => {
          this.deleteItem (index, row);
        }).catch(() => {
          
        });
      },
      async beforeUpload(file) {
        // this.handleSuccess(null, file, null);
      },
      async handleSuccess (res, file){
        // if(file.type!=undefined){
        //   // console.log(file.type)
        //   // this.item.imageFile = file.raw;
        // }
      },
      async handlePictureRemove(file) {
        this.$refs.upload.clearFiles();
      },
      async handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      async handlePictureDownload(file) {
        console.log(file);
      },
      async loadBrand () {
        axios.post(
          this.apiUrl+'/products?action=listBrand',
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.selectsBrand.options = response.data.rows;
        })
        .catch(error => {
          this.error = error;
        });

      },
      async loadCategory () {
        axios.post(
          this.apiUrl+'/products?action=listCategory',
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.selectsCategory.options = response.data.rows;
        })
        .catch(error => {
          this.error = error;
        });

      },
      async loadItems () {
        axios.post(
          this.apiUrl+'/products',
          { 
            'q': this.textSearch,
            'pageSize': this.pagination.pageSize,
            'currentPage': this.pagination.currentPage,
            'sortBy': this.pagination.sortBy,
            'orderBy': this.pagination.orderBy,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.items = response.data.rows;
          this.pagination.total = parseInt(response.data.total);
        })
        .catch(error => {
          this.error = error;
        });

      },
      async newItem (){
        this.listMode = false;
        this.action = 'new';

        this.fileList = [];

        this.item.imageUrl = '';
        this.item.name = '';
        this.item.brand = '',
        this.item.category = '';
        this.item.srp = 0.00;
        this.item.wsp = 0.00;
        this.item.deposit = 0.00;
        this.item.startDate = '';
        this.item.endDate = '';
        this.item.deliveryDate = '';
        this.item.remark = '';
        this.item.status = '';

      },
      async addItem () {
        this.item.startDate = this.formatDate(this.item.startDate)
        this.item.endDate = this.formatDate(this.item.endDate)

        const formData = new FormData();
        if(this.$refs.upload.uploadFiles.length>0){
          formData.append('file', this.$refs.upload.uploadFiles[0].raw);
        }
        formData.append('name', this.item.name);
        formData.append('brand', this.item.brand);
        formData.append('category', this.item.category);
        formData.append('srp', this.item.srp);
        formData.append('wsp', this.item.wsp);
        formData.append('deposit', this.item.deposit);
        formData.append('startDate', this.item.startDate);
        formData.append('endDate', this.item.endDate);
        formData.append('deliveryDate', this.item.deliveryDate);
        formData.append('remark', this.item.remark);
        formData.append('status', this.item.status);

        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/products?action=newProduct', formData,
            {
              headers: {
                // 'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data'
              }
            }
          );

          this.listMode = true;
          this.loadItems();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'เพิ่มรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          // console.error('Error submitting form:', error);
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: error
          })
        }
      },
      async editItem (index, row) {
        this.listMode = false
        this.action = 'edit'

        this.item = row;

        this.fileList = [{
          name: row.imageFile,
          url: row.imageUrl,
        }];
        
      },
      async updateItem () {
        this.item.startDate = this.formatDate(this.item.startDate)
        this.item.endDate = this.formatDate(this.item.endDate)
        
        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/products?action=updateProduct', this.item,
            {
              headers: {
                'Content-Type': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data'
              }
            }
          );

          this.listMode = true;
          this.loadItems();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'บันทึกรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          // console.error('Error submitting form:', error);
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: error+'<br />'
          })
        }
      },
      async deleteItem (index, row) {
        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/products?action=deleteProduct', row,
            {
              headers: { 'Content-Type': 'multipart/form-data' }
            }
          );

          this.loadItems();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'ลบรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: error
          })
        }
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val;
        this.loadItems();
      },
      handleCurrentChange(val) {
        this.pagination.currentPage = val;
        this.loadItems();
      },
      sortChange (param) {
        this.pagination.sortBy = param.prop;
        this.pagination.orderBy = param.order;
        this.loadItems();
      },
    },
  }
</script>

<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
</style>
