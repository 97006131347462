<template>
  <div>
    <div class="card">
      <!-- <div class="card-header">
        <h5 class="card-title">Payment Settings</h5>
      </div> -->
      <div class="card-body row">

        <div class="col-sm-12">
          <div class="pull-left"><h5 class="card-title">Payment Settings</h5></div>
          <div class="pull-right" v-if="listMode">
            <el-input prefix-icon="el-icon-search" v-model="textSearch" size="small" placeholder="Search"
              @input="loadBankAccount"
            />
          </div>
        </div>

        <div class="col-sm-12" v-if="listMode">
          <el-table :data="tableData" header-row-class-name="text-primary">
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="bankName" label="ธนาคาร">
              <template slot-scope="props">
                <img :src="props.row.logo" style="width:25px;" /> {{ props.row.bankName }}
              </template>
            </el-table-column>
            <el-table-column prop="accountNumber" label="เลขที่บัญชี"></el-table-column>
            <el-table-column prop="accountName" label="ชื่อบัญชี"></el-table-column>
            <el-table-column class-name="action-buttons td-actions" align="right" label="Actions">
              <template slot="header" slot-scope="scope">
                <p-button type="info" round @click="listMode=false; action='new'"> add new </p-button>
              </template>
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="editBankAccount(props.$index, props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="confirmToDelete(props.$index, props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="col-sm-12" v-else>
          <div class="extended-forms">
            <div class="card">
              <div class="card-body">

                <div class="row">

                  <div class="col-md-4">
                    <h5 class="card-title">ธนาคาร</h5>
                    <div class="row">
                      <div class="col-sm-12">
                        <fg-input>
                          <el-select class="select-success" size="large" placeholder="เลือกธนาคาร" v-model="item.bankCode">
                            <el-option v-for="option in selects.bankName"
                              class="select-success"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label">
                            </el-option>
                          </el-select>
                        </fg-input>
                      </div>                     
                    </div>
                  </div>

                  <div class="col-md-4">
                    <h5 class="card-title">หมายเลขบัญชี</h5>
                    <fg-input placeholder="กรอกหมายเลขบัญชี" v-model="item.accountNumber"></fg-input>
                  </div>
                  <div class="col-md-4">
                    <h5 class="card-title">ชื่อบัญชี</h5>
                    <fg-input placeholder="กรอกชื่อบัญชี" v-model="item.accountName"></fg-input>
                  </div>
                </div>
                <br/>

                <div class="row">
                  <div class="col-md-12">
                    <h5 class="card-title">หมายเหตุ</h5>
                    <fg-input placeholder="" v-model="item.remark"></fg-input>
                  </div>
                </div>
                <br/>

                <!-- <div class="row">
                  <div class="col-md-2">
                    <h5 class="card-title">เปิดใช้งาน</h5>
                    <div class="col-md-6">
                      <p-switch v-model="item.isActive" type="primary" on-text="ON" off-text="OFF" value="1"></p-switch>
                    </div>
                  </div>
                </div> -->

                <br />

                <div class="row">
                  <div class="col-md-5"></div>
                  <div class="col-md-6">
                    <p-button type="info" round @click="newBankAccount()" v-if="action=='new'"> Add </p-button>
                    <p-button type="info" round @click="updateBankAccount()" v-if="action=='edit'"> Update </p-button>
                    &nbsp;
                    <p-button type="danger" round @click="listMode=true"> cancel </p-button>
                  </div>
                </div>

                <br/><br/>
              </div>
            </div> <!-- end card -->
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue'
  import {Table, TableColumn} from 'element-ui'
  import {DatePicker, TimeSelect, Slider, Input, Button, Select, Option, MessageBox } from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import NotificationTemplate from 'src/components/Dashboard/Views/Components/NotificationTemplate'
  import axios from 'axios'

  Vue.use(Table)
  Vue.use(TableColumn)
  
  export default{
    components: {
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      [Slider.name]: Slider,
      [Input.name]: Input,
      [Button.name]: Button,
      [Option.name]: Option,
      [Select.name]: Select,
      PSwitch,
      NotificationTemplate
    },
    data () {
      return {
        listMode: true,
        tableData: [],
        selects: {
          simple: '',
          bankName:[
            { value:'KASITHBK', label:'กสิกรไทย' },
            { value:'BKKBTHBK', label:'กรุงเทพ' },
            { value:'SICOTHBK', label:'ไทยพาณิชย์' },
            { value:'AYUDTHBK', label:'กรุงศรีอยุธยา' },
            { value:'UOVBTHBK', label:'ยูโอบี จำกัด (มหาชน)' },
            { value:'UBOBTHBK', label:'ซีไอเอ็มบีไทย' },
            { value:'LAHRTHB2', label:'แอนด์ เฮ้าส์ จำกัด (มหาชน)' },
          ],
          multiple: 'ARS'
        },
        textSearch: '',
        action: 'add',
        item: {
          bankCode : '',
          accountNumber: '',
          accountName: '',
          remark: '',
          // isActive: true
        },
        type: ['', 'info', 'success', 'warning', 'danger'],
        apiUrl: process.env.NODE_ENV === 'production'?'https://www.hbrxws.com/api':'http://localhost/hbrxws/api',
      }
    },
    mounted() {
      this.loadBankAccount()
    },
    methods: {
      confirmToDelete (index, row) {
        MessageBox.confirm('This will permanently delete. Continue?', 'Warning', {
          confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning'
        }).then(() => {
          this.deleteBankAccount (index, row);
        }).catch(() => {
          
        });
      },
      async loadBankAccount () {
        axios.get(this.apiUrl+'/bankAccount?q='+this.textSearch)
        .then(response => {
          this.tableData = response.data.rows;
        })
        .catch(error => {
          this.error = error;
        });
      },
      async newBankAccount () {
        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/bankAccount?action=newBankAccount', this.item,
            {
              headers: {
                'Content-Type': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data'
              }
            }
          );

          this.item.bankCode = '';
          this.item.accountNumber = '';
          this.item.accountName = '';
          this.item.remark = '';
          // this.item.isActive = true;

          this.listMode = true;
          this.loadBankAccount();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'เพิ่มรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          // console.error('Error submitting form:', error);
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: error
          })
        }
      },
      async editBankAccount (index, row) {
        this.listMode = false
        this.action = 'edit'

        // if(row.isActive=='true'){
        //   row.isActive = true;
        // }
        // if(row.isActive=='false'){
        //   row.isActive = false;
        // }

        this.item = row;
        console.log(row)
      },
      async updateBankAccount () {
        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/bankAccount?action=updateBankAccount', this.item,
            {
              headers: {
                'Content-Type': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data'
              }
            }
          );

          this.listMode = true;
          this.loadBankAccount();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'บันทึกรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          // console.error('Error submitting form:', error);
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: error
          })
        }
      },
      async deleteBankAccount (index, row) {
        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/bankAccount?action=deleteBankAccount', row,
            {
              headers: { 'Content-Type': 'multipart/form-data' }
            }
          );

          this.loadBankAccount();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'ลบรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: error
          })
        }
      },
    }
  }
</script>

<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
</style>
