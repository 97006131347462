<template>
  <div>
    <div class="card">
      <!-- <div class="card-header"><h5 class="card-title">Orders</h5></div> -->
      <div class="card-body row">

        <div class="col-sm-12">
          <div class="pull-left">
            <h5 class="card-title">Order Summary / Orders Detail</h5>
          </div>
          <div class="pull-right">
           
          </div>
        </div>

        <div class="col-sm-12" >
          <div class="row">
            <div class="col-md-6">
              <h6 class="card-title">Shop Name : {{shopName}}</h6>
              <h6 class="card-title">Lot Name : {{lotname}}</h6>
            </div>
          </div>

          <el-table :data="orderItems" header-row-class-name="text-primary" :summary-method="getSummaries" show-summary>
            <!-- <el-table-column type="index"></el-table-column> -->
            <el-table-column prop="id" label="ID" width="50">
              <template slot-scope="props">
                <span>{{ props.row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column label="" width="100">
              <template slot-scope="props">
                <div class="img-container"><img :src="props.row.imageUrl" alt=""></div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="entryDate" label="Date"></el-table-column> -->
            <el-table-column prop="name" label="Name"></el-table-column>
            <el-table-column prop="srp" label="srp"></el-table-column>
            <el-table-column prop="wsp" label="wsp"></el-table-column>
            <el-table-column prop="deposit" label="Deposit"></el-table-column>
            <el-table-column prop="qty" label="QTY"></el-table-column>
           
          </el-table>
          <!-- <br /> -->
          <!-- <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.currentPage"
              :page-sizes="pagination.pageSizes"
              :page-size="pagination.pageSize"
              :total="pagination.total"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div> -->

          <!-- <div class="row">
            <div class="col-md-5"></div>
            <div class="col-md-6">
              <p-button type="danger" round @click="listMode=true"> Close </p-button>
            </div>
          </div> -->

        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {Table, TableColumn} from 'element-ui'
  import {Input, Button, Select, Option, Autocomplete, Pagination} from 'element-ui'
  import { Modal } from 'src/components/UIComponents'
  import NotificationTemplate from 'src/components/Dashboard/Views/Components/NotificationTemplate'
  import axios from 'axios'

  Vue.use(Table)
  Vue.use(TableColumn)
  export default{
    components: {
      [Input.name]: Input,
      [Button.name]: Button,
      [Option.name]: Option,
      [Select.name]: Select,
      [Autocomplete.name]: Autocomplete,
      [Pagination.name]: Pagination,
      Modal,
    },
    data () {
      return {
        listMode: true,
        textSearch: '',
        pagination: {
          pageSizes: [10, 20, 50],
          pageSize: 10,
          total: 0,
          currentPage: 1,
        },
        orderItems: [],
        products: [],
        showOrderItem: true,
        showSelectProduct: false,
        isNewOrder: false,
        cartItems: [],
        selectStatus : [
          { value:'Confirmed', label:'Confirmed' },
          { value:'Modified', label:'Modified' },
          { value:'Canceled', label:'Canceled' },
          { value:'Partially Shipped', label:'Partially Shipped' },
          { value:'Completed', label:'Completed' },
        ],
        disabledShopname: false,
        orderDetail : {
            orderID : 0,
            userID : 0,
            shopname : '',
            lotname : '',
            remark: '',
            paid: 0,
            status: 'Confirmed',
        },
        links: [],
        slips: [],
        shopName: '',
        lotname: '',
        apiUrl: process.env.NODE_ENV === 'production'?'https://www.hbrxws.com/api':'http://localhost/hbrxws/api',
      }
    },
    mounted() {
      const token = localStorage.getItem('authToken');

      axios.post(
        this.apiUrl+'/user?action=getUserDetail', { token:token },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      .then(response => {
        // if(response.data.role!='admin'){
        //   this.$router.push('/login');
        // }
      });

      if(this.$route.params.id!=''){
        let params = []
        params.id = this.$route.params.id
        this.loadOrderDetail(params)
      }else{
        this.loadOrders();
      }

    },
    methods: {
      getSummaries (param) {
        const { columns } = param
        const sums = []

        columns.forEach((column, index) => {
          if (index === 2) {
            sums[index] = 'Total'
          } else if (index < columns.length - 1) {
            sums[index] = ''
          } else {
            let sum = 0
            this.orderItems.forEach((obj) => {
              sum += obj.quantity * obj.price
            })
            sums[index] = `฿ ${sum}`
          }
        })
        return sums
      },
      handleClose (tag) {
        this.tags.dynamicTags.splice(this.tags.dynamicTags.indexOf(tag), 1)
      },
      handleInputConfirm () {
        let inputValue = this.tags.inputValue
        if (inputValue) {
          this.tags.dynamicTags.push(inputValue)
        }
        this.tags.inputVisible = false
        this.tags.inputValue = ''
      },
      async loadOrders () {
        axios.post(
          this.apiUrl+'/orders',
          { 
            'pageSize': this.pagination.pageSize,
            'currentPage': this.pagination.currentPage,
            'q': this.textSearch,
            // 'id': this.$route.params.id,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.orders = response.data.rows;
          this.pagination.total = parseInt(response.data.total);
        })
        .catch(error => {
          this.error = error;
        });

      },
      async loadOrderDetail (row) {
        
        this.isNewOrder = false;

        this.orderDetail = row
        this.orderDetail.orderID = row.id;

        axios.post(
          this.apiUrl+'/orders?action=orderDetail',
          { 'id': row.id },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          console.log(response.data.detail)

          this.shopName = response.data.detail.shopname;
          this.lotname = response.data.detail.lotname;

          this.state1 = row.fullname
          this.orderItems = response.data.rows;

          this.loadSlip(row.id);
        })
        .catch(error => {
          this.error = error;
        });
      },
      async newOrder (){
        this.listMode = false
        this.isNewOrder = true;
        this.disabledShopname = false;

        this.orderDetail.shopname = '';
        this.orderDetail.lotname = '';
        this.orderDetail.status = '';

        this.cartItems = []
        this.orderItems = this.cartItems
      },
      async loadProducts () {
        axios.get(
          this.apiUrl+'/products?action=listActiveProduct',
        )
        .then(response => {
          this.products = response.data.rows;
        })
        .catch(error => {
          this.error = error;
        });

      },
      async addCart (row){
        await axios.post(
          this.apiUrl+'/orders?action=addCart', {'id': row.id} ,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          const item = response.data
          item.price = parseFloat(item.srp)
          
          this.cartItems.push(item);
          this.orderItems = this.cartItems;
        })
        .catch(error => {
          this.error = error;
        });

      },
      async addOrder(){
        if(this.orderDetail.userID==0){
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: 'ไม่ได้เลือกร้าน !'
          })
          return false;
        }

        await axios.post(
          this.apiUrl+'/orders?action=addOrder', 
          {
            'customer': this.orderDetail,
            'items': this.cartItems,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {

          if(response.data.success){
            this.cartItems = []
            this.orderItems = this.cartItems

            this.loadOrders();
            this.listMode = true;

            this.$notify({
              component: NotificationTemplate,
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'success',
              message: 'เพิ่ม Order เรียบร้อยแล้ว'
            })

          }else{
            this.$notify({
              component: NotificationTemplate,
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'danger',
              message: response.data.errorMsg
            })
          }

        })
        .catch(error => {
          this.$notify({
              component: NotificationTemplate,
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'danger',
              message: 'API Error !'
            })
        });

        
      },
      async editOrder (index, row) {
        this.listMode = false;
        this.disabledShopname = true;

        this.loadOrderDetail(row)
      },
      async updateOrder () {
        await axios.post(
          this.apiUrl+'/orders?action=updateOrder', 
          this.orderDetail,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          this.loadOrders();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'บันทึก Order เรียบร้อยแล้ว'
          })
        })
        .catch(error => {
          this.error = error;
        });

        this.listMode = true;
      },
      async searchCustomer(queryString, cb) {
        await axios.post(
          this.apiUrl+'/userAccount',
          { q: queryString },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          cb(response.data.rows);
        })
        .catch(error => {
          this.error = error;
        });
      },
      async selectCustomer(item) {
        this.orderDetail.userID = item.id;
        // this.orderDetail.address = item.address;
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val;
        this.loadOrders();
      },
      handleCurrentChange(val) {
        this.pagination.currentPage = val;
        this.loadOrders();
      },
      printOrderDetail (row) {
        window.open('http://localhost/hbrxws/api/orders?action=printOrderDetail&id='+row.id,'_new')
      },
      async loadSlip(docID){
        axios.post(
          this.apiUrl+'/orders?action=loadSlips', { 'orderID':docID },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.slips = response.data.rows;
        })
        .catch(error => {
          this.error = error;
        });
      },
    }
  }
</script>

<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
  .blink {
      animation: blinker 1.5s linear infinite;
      color: red;
      font-family: sans-serif;
  }
  @keyframes blinker {
      50% {
          opacity: 0;
      }
  }
</style>
