<template>
  <div>
    <div class="card">
      <!-- <div class="card-header"><h5 class="card-title">Products</h5></div> -->
      <div class="card-body row">

        <div class="col-sm-12">
          <div class="pull-left"><h5 class="card-title">Product Summary</h5></div>
          <div class="pull-right" v-if="listMode">
            <div class="col-sm-12">
              <el-input prefix-icon="el-icon-search" v-model="textSearch" size="small" placeholder="Name & Brand"
                @input="loadItems"
              />
            </div>
          </div>
        </div>

        <div class="col-sm-12" v-if="listMode">
          <el-table :data="items" ref="filterTable" @sort-change="sortChange" header-row-class-name="text-primary">
            <!-- <el-table-column type="index"></el-table-column> -->
            <el-table-column prop="id" label="ID" width="60"></el-table-column>
            <el-table-column width="100" prop="imageUrl">
              <template slot-scope="props">
                <div class="img-container"><img :src="props.row.imageUrl" alt=""></div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="Item Name" width="200">
              <template slot="header" slot-scope="scope">
                Name
                <!-- <el-input v-model="textSearch" size="small" @input="loadItems" /> -->
              </template>
            </el-table-column>
            <el-table-column prop="brand" label="Brand"></el-table-column>
            <el-table-column prop="qtyOrder" label="Ordered"></el-table-column>
            <!-- <el-table-column prop="shopOrdered" label="Shop Name"></el-table-column> -->
            <el-table-column class-name="action-buttons td-actions" width="140" align="right" label="Actions">
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="showDetail(props.$index, props.row)">
                  <i class="nc-icon nc-paper"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
          <br />
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.currentPage"
              :page-sizes="pagination.pageSizes"
              :page-size="pagination.pageSize"
              :total="pagination.total"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>

        <div class="col-sm-12" v-else>
          <div class="row">
            <div class="col-md-3">
              <img :src="productImage" style="width:100px;"/>
            </div>
            <div class="col-md-9">
              <h6 class="card-title">{{productName}}</h6>
            </div>
          </div>

          <el-table :data="orders" header-row-class-name="text-primary">
            <el-table-column prop="id" label="ID" width="50">
              <template slot-scope="props">
                <span class="blink">{{ props.row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="entryDate" label="Date" width="100"></el-table-column>
            <!-- <el-table-column label="" width="100">
              <template slot-scope="props">
                <div class="img-container"><img :src="props.row.imageUrl" alt=""></div>
              </template>
            </el-table-column> -->
            <!-- <el-table-column prop="itemName" label="Item Name"></el-table-column> -->
            <el-table-column prop="shopname" label="Shop Name"></el-table-column>
            <el-table-column prop="qty" label="Qty"></el-table-column>
            <el-table-column prop="status" label="Shipment"></el-table-column>
          </el-table>
          <br />
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.currentPage"
              :page-sizes="pagination.pageSizes"
              :page-size="pagination.pageSize"
              :total="pagination.total"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>

          <div class="row">
            <div class="col-md-5"></div>
            <div class="col-md-6">
              <p-button type="danger" round @click="listMode=true"> Close </p-button>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue'
  import {Table, TableColumn} from 'element-ui'
  import {DatePicker, TimeSelect, Input, Button, Select, Option, Upload, Dialog, Pagination} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import NotificationTemplate from 'src/components/Dashboard/Views/Components/NotificationTemplate'
  import axios from 'axios'

  Vue.use(Table)
  Vue.use(TableColumn)
  export default{
    components: {
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      [Input.name]: Input,
      [Button.name]: Button,
      [Option.name]: Option,
      [Select.name]: Select,
      [Upload.name]: Upload,
      [Dialog.name]: Dialog,
      [Pagination.name]: Pagination,
      PSwitch,
    },
    data () {
      return {
        selectsBrand: {
          simple: '',
          options:[
            { value:'KASITHBK', label:'bbbb' },
          ],
          multiple: 'ARS'
        },
        selectsCategory: {
          simple: '',
          options:[
            { value:'KASITHBK', label:'cccc' },
          ],
          multiple: 'ARS'
        },
        orders: [],
        productName: '',
        productImage: '',
        datePicker: '',
        dateTimePicker: '',
        timePicker: '',
        dialogImageUrl: '',
        dialogVisible: false,
        fileList: [],
        disabled: false,
        listMode: true,
        items: [],
        action: 'add',
        item :{
          imageFile : null,
          imageUrl : '',
          name : '',
          brand: '',
          category: '',
          srp: 0.00,
          wsp: 0.00,
          deposit: 0.00,
          startDate: '',
          endDate: '',
          deliveryDate: '',
          remark: '',
        },
        textSearch: '',
        pagination: {
          pageSizes: [10, 20, 50],
          pageSize: 10,
          total: 0,
          currentPage: 1,
          sortBy: 'id',
          orderBy: 'desc',
        },
        apiUrl: process.env.NODE_ENV === 'production'?'https://www.hbrxws.com/api':'http://localhost/hbrxws/api',
      }
    },
    mounted() {
      // this.loadBrand()
      // this.loadCategory()
      this.loadItems()
    },
    methods: {
      async handlePictureRemove(file) {
        this.$refs.upload.clearFiles();
      },
      async handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      async handlePictureDownload(file) {
        console.log(file);
      },

      async loadBrand () {
        axios.post(
          this.apiUrl+'/products?action=listBrand',
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.selectsBrand.options = response.data.rows;
        })
        .catch(error => {
          this.error = error;
        });

      },
      async loadCategory () {
        axios.post(
          this.apiUrl+'/products?action=listCategory',
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.selectsCategory.options = response.data.rows;
        })
        .catch(error => {
          this.error = error;
        });

      },
      async loadItems () {
        axios.post(
          // this.apiUrl+'/products?action=productSummary',
          this.apiUrl+'/products?action=productOrdered',
          { 
            'q': this.textSearch,
            'pageSize': this.pagination.pageSize,
            'currentPage': this.pagination.currentPage,
            'sortBy': this.pagination.sortBy,
            'orderBy': this.pagination.orderBy,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          // console.log(response.data.rows)
          this.items = response.data.rows;
          this.pagination.total = parseInt(response.data.total);
        })
        .catch(error => {
          this.error = error;
        });

      },
      async newItem (){
        this.listMode = false;
        this.action = 'new';

        // this.$refs.upload.clearFiles();
        this.item.imageUrl = '';
        this.item.name = '';
        this.item.brand = '',
        this.item.category = '';
        this.item.srp = 0.00;
        this.item.wsp = 0.00;
        this.item.deposit = 0.00;
        this.item.startDate = '';
        this.item.endDate = '';
        this.item.deliveryDate = '';
        this.item.remark = '';

      },
      async addItem () {
        const formData = new FormData();
        if(this.$refs.upload.uploadFiles.length>0){
          formData.append('file', this.$refs.upload.uploadFiles[0].raw);
        }
        formData.append('name', this.item.name);
        formData.append('brand', this.item.brand);
        formData.append('category', this.item.category);
        formData.append('srp', this.item.srp);
        formData.append('wsp', this.item.wsp);
        formData.append('deposit', this.item.deposit);
        formData.append('startDate', this.item.startDate);
        formData.append('endDate', this.item.endDate);
        formData.append('deliveryDate', this.item.deliveryDate);
        formData.append('remark', this.item.remark);

        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/products?action=newProduct', formData,
            {
              headers: {
                // 'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data'
              }
            }
          );

          this.item.name = '';
          this.item.brand = '';
          this.item.startDate = '';
          this.item.endDate = '';

          this.listMode = true;
          this.loadItems();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'เพิ่มรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          // console.error('Error submitting form:', error);
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: error
          })
        }
      },
      async showDetail (index, row) {
        this.listMode = false
        this.action = 'edit'

        this.productName = row.name
        this.productImage = row.imageUrl
        this.loadOrders(row);
      },
      async loadOrders (params) {
        // console.log(params)
        axios.post(
          this.apiUrl+'/orders',
          { 
            'action': 'orderProductsSummary',
            'pageSize': this.pagination.pageSize,
            'currentPage': this.pagination.currentPage,
            'q': this.textSearch,
            'id': params.id,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.orders = response.data.rows;
          this.pagination.total = parseInt(response.data.total);
        })
        .catch(error => {
          this.error = error;
        });

      },
      handleSizeChange(val) {
        this.pagination.pageSize = val;
        this.loadItems();
      },
      handleCurrentChange(val) {
        this.pagination.currentPage = val;
        this.loadItems();
      },
      sortChange (param) {
        this.pagination.sortBy = param.prop;
        this.pagination.orderBy = param.order;
        this.loadItems();
      }
    }
  }
</script>

<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
</style>
