<template>
  <div>
    <div class="card">
      <!-- <div class="card-header">
        <h5 class="card-title">User Account</h5>
      </div> -->
      <div class="card-body row">

        <div class="col-sm-12">
          <div class="pull-left"><h5 class="card-title">User Account</h5></div>
          <div class="pull-right" v-if="listMode">
            <el-input prefix-icon="el-icon-search" v-model="textSearch" size="small" placeholder="Search"
              @input="loadUserAccount"
            />
          </div>
        </div>

        <div class="col-sm-12" v-if="listMode">
          <el-table :data="tableData" header-row-class-name="text-primary" @sort-change="sortChange">
            <!-- <el-table-column type="index"></el-table-column> -->
            <el-table-column prop="id" label="ID" sortable="custom"></el-table-column>
            <el-table-column prop="shopname" label="Shop Name" sortable="custom"></el-table-column>
            <el-table-column prop="username" label="Username" sortable="custom"></el-table-column>
            <el-table-column prop="role" label="Permission" sortable="custom"></el-table-column>
            <el-table-column prop="status" label="Active" sortable="custom"></el-table-column>
            <!-- <el-table-column prop="lastname" label="นามสกุล"></el-table-column> -->
            <el-table-column class-name="action-buttons td-actions" align="right" label="Actions">
              <template slot="header" slot-scope="scope">
                <p-button type="info" round @click="newUserAccount()"> add new </p-button>
              </template>
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="editUserAccount(props.$index, props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="confirmToDelete(props.$index, props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
          <br />
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.currentPage"
              :page-sizes="pagination.pageSizes"
              :page-size="pagination.pageSize"
              :total="pagination.total"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>

        <div class="col-sm-12" v-else>
          <div class="extended-forms">
            <div class="card">
              <div class="card-body">

                <div class="row">
                  <div class="col-md-4">
                    <h5 class="card-title">Username</h5>
                    <fg-input placeholder="กรอก Username" v-model="item.username"></fg-input>
                  </div>
                  <div class="col-md-4">
                    <h5 class="card-title">Password</h5>
                    <fg-input type="password" placeholder="กรอก Password" v-model="item.password"></fg-input>
                  </div>
                </div>
                <br/>
                <div class="row">
                  <div class="col-md-4">
                    <h5 class="card-title">Shop Name</h5>
                    <fg-input placeholder="" v-model="item.shopname"></fg-input>
                  </div>
                </div>
                <br/>
                <div class="row">
                  <div class="col-md-4">
                    <h5 class="card-title">Contace Name</h5>
                    <fg-input placeholder="" v-model="item.firstname"></fg-input>
                  </div>
                  <div class="col-md-4">
                    <h5 class="card-title">E-Mail</h5>
                    <fg-input placeholder="" v-model="item.email"></fg-input>
                  </div>
                  <div class="col-md-4">
                    <h5 class="card-title">Tel.</h5>
                    <fg-input placeholder="" v-model="item.tel"></fg-input>
                  </div>
                  <!-- <div class="col-md-4">
                    <h5 class="card-title">นามสกุล</h5>
                    <fg-input placeholder="กรอกนามสกุล" v-model="item.lastname"></fg-input>
                  </div> -->
                </div>
                <br/>

                <!-- <div class="row">
                  <div class="col-md-12">
                    <h5 class="card-title">ที่อยู่</h5>
                    <fg-input placeholder="" v-model="item.address"></fg-input>
                  </div>
                </div>
                <br/> -->

                <!-- <div class="row">
                  <div class="col-md-4">
                    <h5 class="card-title">E-Mail</h5>
                    <fg-input placeholder="" v-model="item.email"></fg-input>
                  </div>
                  <div class="col-md-4">
                    <h5 class="card-title">เบอร์โทรติดต่อ</h5>
                    <fg-input placeholder="" v-model="item.tel"></fg-input>
                  </div>
                </div>
                <br/> -->

                <!-- <div class="row">
                  <div class="col-md-12">
                    <h5 class="card-title">หมายเหตุ</h5>
                    <fg-input placeholder="" v-model="item.remark"></fg-input>
                  </div>
                </div>
                <br/> -->

                <div class="row">
                  <div class="col-md-4">
                    <h5 class="card-title">Permission</h5>
                    <div class="row">
                      <div class="col-sm-12">
                        <fg-input>
                          <el-select class="select-success" size="large" placeholder="" v-model="item.role">
                            <el-option class="select-success" value="admin" label="Admin"></el-option>
                            <el-option class="select-success" value="user" label="User"></el-option>
                          </el-select>
                        </fg-input>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <h5 class="card-title">Active</h5>
                    <div class="row">
                      <div class="col-sm-12">
                        <fg-input>
                          <el-select class="select-success" size="large" placeholder="" v-model="item.status">
                            <el-option class="select-success" value="YES" label="YES"></el-option>
                            <el-option class="select-success" value="NO" label="NO"></el-option>
                          </el-select>
                        </fg-input>
                      </div>                     
                    </div>
                  </div>
                </div>

                <br />

                <div class="row">
                  <div class="col-md-5"></div>
                  <div class="col-md-6">
                    <p-button type="info" round @click="addUserAccount()" v-if="action=='new'"> Add </p-button>
                    <p-button type="info" round @click="updateUserAccount()" v-if="action=='edit'"> Update </p-button>
                    &nbsp;
                    <p-button type="danger" round @click="listMode=true"> cancel </p-button>
                  </div>
                </div>

                <br/><br/>
              </div>
            </div> <!-- end card -->
          </div>
        </div>

      </div>
    </div>
    
  </div>
</template>

<script>
  import Vue from 'vue'
  import {Table, TableColumn} from 'element-ui'
  import {DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Pagination, MessageBox} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import NotificationTemplate from 'src/components/Dashboard/Views/Components/NotificationTemplate'
  import axios from 'axios'

  Vue.use(Table)
  Vue.use(TableColumn)

  export default{
    components: {
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      [Slider.name]: Slider,
      [Tag.name]: Tag,
      [Input.name]: Input,
      [Button.name]: Button,
      [Option.name]: Option,
      [Select.name]: Select,
      [Pagination.name]: Pagination,
      PSwitch,
      NotificationTemplate
    },
    data () {
      return {
        listMode: true,
        tableData: [],
        switches: {
          defaultOn: true,
          defaultOff: false,
          plainOn: true,
          plainOff: false,
          withIconsOn: true,
          withIconsOff: false
        },
        action: 'add',
        item: {
          username: '',
          password: '',
          shopname: '',
          firstname: '',
          lastname: '',
          address: '',
          email: '',
          tel: '',
          remark: '',
          roler: '',
          // active: true
        },
        textSearch: '',
        pagination: {
          pageSizes: [10, 20, 50],
          pageSize: 10,
          total: 0,
          currentPage: 1,
          sortBy: 'id',
          orderBy: 'desc',
        },
        apiUrl: process.env.NODE_ENV === 'production'?'https://www.hbrxws.com/api':'http://localhost/hbrxws/api',
      }
    },
    mounted() {
      this.loadUserAccount();
    },
    methods: {
      confirmToDelete (index, row) {
        MessageBox.confirm('This will permanently delete. Continue?', 'Warning', {
          confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning'
        }).then(() => {
          this.deleteUserAccount (index, row);
        }).catch(() => {
          
        });
      },
      async loadUserAccount () {
        axios.post(
          this.apiUrl+'/userAccount',
          { 
            'pageSize': this.pagination.pageSize,
            'currentPage': this.pagination.currentPage,
            'sortBy': this.pagination.sortBy,
            'orderBy': this.pagination.orderBy,
            'q': this.textSearch,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.tableData = response.data.rows;
          this.pagination.total = parseInt(response.data.total);
        })
        .catch(error => {
          this.error = error;
        });

      },
      async newUserAccount (){
        this.listMode=false;
        this.action='new'

        this.item.username = '';
        this.item.password = '';
        this.item.shopname = '';
        this.item.firstname = '';
        this.item.lastname = '';
        this.item.address = '';
        this.item.email = '';
        this.item.tel = '';
        this.item.remark = '';
        this.item.status = '';
      },
      async addUserAccount () {
        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/userAccount?action=newUserAccount', this.item,
            {
              headers: {
                'Content-Type': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data'
              }
            }
          );

          this.item.username = '';
          this.item.password = '';
          this.item.shopname = '';
          this.item.firstname = '';
          this.item.lastname = '';
          this.item.address = '';
          this.item.email = '';
          this.item.tel = '';
          this.item.remark = '';
          this.item.status = '';

          this.listMode = true;
          this.loadUserAccount();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'เพิ่มรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          console.error('Error submitting form:', error);
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: 'Error !'
          })
        }
      },
      async editUserAccount (index, row) {
        this.listMode = false
        this.action = 'edit'

        this.item = row;
      },
      async updateUserAccount () {
        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/userAccount?action=updateUserAccount', this.item,
            {
              headers: {
                'Content-Type': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data'
              }
            }
          );

          this.listMode = true;
          this.loadUserAccount();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'บันทึกรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          console.error('Error submitting form:', error);
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: 'Error !'
          })
        }
      },
      async deleteUserAccount (index, row) {
        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/userAccount?action=deleteUserAccount', row,
            {
              headers: { 'Content-Type': 'multipart/form-data' }
            }
          );

          this.loadUserAccount();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'ลบรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: error
          })
        }
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val;
        this.loadUserAccount();
      },
      handleCurrentChange(val) {
        this.pagination.currentPage = val;
        this.loadUserAccount();
      },
      sortChange (param) {
        this.pagination.sortBy = param.prop;
        this.pagination.orderBy = param.order;
        this.loadUserAccount();
      }
    }
  }
</script>

<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
</style>
